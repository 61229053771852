import React from 'react'
import './ConferencePage.css';

function ConferencePage() {
    return (
        <>
        <div className='conference-container'>
          <div className='conference-wrapper'>
            <h1>Past Conferences</h1>
            <p>eCourts - 2024</p>
          </div>
        </div>
        </>
    );
  }


export default ConferencePage;